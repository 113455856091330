import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { connect } from "react-redux";
import { ACTION_TYPES } from "../redux/user/orders/actions";
interface OwnProps {}

interface DispatchProps {}

interface SavedSodexoCardsProps extends OwnProps, DispatchProps {
  getSodexoCardDetailAction: Function;

  isSodexoCardDeletedSuccess: false;
  isSodexoCardDeletedError: false;

  isSodexoCardGetSuccess: false;
  isSodexoCardGetError: false;
  deleteSodexoCardDetailAction: Function;
  sodexoCardDetails: {
    sodaxoGetCardResponse: { maskedPan: ""; ownerName: "" };
  };
}

const SavedSodexoCards: React.FC<SavedSodexoCardsProps> = ({
  getSodexoCardDetailAction,
  isSodexoCardDeletedSuccess,
  isSodexoCardDeletedError,
  isSodexoCardGetSuccess,
  isSodexoCardGetError,
  deleteSodexoCardDetailAction,
  sodexoCardDetails,
}) => {
  useIonViewWillEnter(() => {
    getSodexoCardDetailAction();
  });

  useEffect(() => {
    if (isSodexoCardGetSuccess) {
      console.log(sodexoCardDetails);
    }
  }, [isSodexoCardGetSuccess]);

  useEffect(() => {
    if (isSodexoCardDeletedSuccess) {
      console.log("Card deleted successfully...");
    }
  }, [isSodexoCardDeletedSuccess]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>SavedSodexoCards...............</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* {sodexoCardDetails.sodaxoGetCardResponse != null &&  */}(
        <>
          <div>{sodexoCardDetails?.sodaxoGetCardResponse?.maskedPan}</div>
          <div>{sodexoCardDetails?.sodaxoGetCardResponse?.ownerName}</div>
          <div onClick={() => deleteSodexoCardDetailAction()}>DELETE</div>
        </>
        ){/* } */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  sodexoCardDetails: state.orders.sodexoCardDetails,
  isSodexoCardGetSuccess: state.orders.isSodexoCardGetSuccess,
  isSodexoCardGetError: state.orders.isSodexoCardGetError,
  isSodexoCardDeletedSuccess: state.orders.isSodexoCardDeletedSuccess,
  isSodexoCardDeletedError: state.orders.isSodexoCardDeletedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getSodexoCardDetailAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_SODEXO_CARD_DETAILS_REQUEST,
      }),
    deleteSodexoCardDetailAction: () =>
      dispatch({
        type: ACTION_TYPES.DELETE_SODEXO_CARD_DETAILS_REQUEST,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedSodexoCards);
